import Auth from '@aws-amplify/auth'
import { Typography } from '@material-ui/core'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import { TFunction } from 'i18next'
import debounce from 'lodash.debounce'
import { ascend, descend, groupBy, keys, prop, sort } from 'ramda'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import { setItemToLocalStorage } from '../../utils/setLocalStorage'
import { useAppState } from '../appState'
import axios from '../axios'
import BrandsCategorySort from '../components/brandsCategorySort'
import CHRBrandsGroupList from '../components/brandsGroupList'
import BrandsHeader from '../components/brandsHeader'
import CHRBrandsSort from '../components/brandsSort'
import CHRContentContainer from '../components/contentContainer'
import AddExtensionPopup from '../components/popups/automaticPopOver/addExtensionPopUp'
import SEO from '../components/seo'
import CHRSpinner from '../components/spinner'
import {
  ALPHABET_LIST,
  baseURL,
  BRANDS_CATEGORIES,
  CASHBACK_RANGES,
  ENDPOINTS,
} from '../constants'
import Layout from '../layouts/defaultLayout'
import { styles } from '../pagesStyles/brandsStyles'
import { tracker } from '../systemLogs'

interface BrandsPageProps {
  t: TFunction
}

const mapWordsToChars = groupBy(({ brandName }: { brandName: string }) => {
  if (ALPHABET_LIST.includes(brandName[0].toUpperCase())) {
    return brandName[0].toUpperCase()
  } else {
    return 'others'
  }
})

const mapAmountToRanges = groupBy(({ commission }: { commission: string }) => {
  // check if commission is a percentage or not
  if (commission.includes('%')) {
    // convert commission to a numerical value instead of string
    const commissionValue: number = parseFloat(commission.split('%')[0])

    // covers values from 0 - 1 (1 is not-included)
    if (commissionValue < CASHBACK_RANGES[1].min) {
      return CASHBACK_RANGES[0].min
    }
    // covers values from 1 - 4 (4 is not-included)
    else if (
      commissionValue >= CASHBACK_RANGES[1].min &&
      commissionValue < CASHBACK_RANGES[2].min
    ) {
      return CASHBACK_RANGES[1].min
    }
    // covers values from 4 - 7 (7 is not-included)
    else if (
      commissionValue >= CASHBACK_RANGES[2].min &&
      commissionValue < CASHBACK_RANGES[3].min
    ) {
      return CASHBACK_RANGES[2].min
    }
    // covers values from 8 - 11 (11 is not-included)
    else if (
      commissionValue >= CASHBACK_RANGES[3].min &&
      commissionValue <= CASHBACK_RANGES[3].max
    ) {
      return CASHBACK_RANGES[3].min
    }
    // covers values from 11 and above
    else if (commissionValue > CASHBACK_RANGES[3].max) {
      return CASHBACK_RANGES[4].min
    }
    // anything else in this range isn't covered.
    else {
      return 'this range is not defined'
    }
  }
  // if commission isn't a percentage
  // range is "others"
  else {
    return 'others'
  }
})

const sortDescByAmount = descend(prop('commission'))
const sortAscByAmount = ascend(prop('commission'))

const BrandsPage = ({ t }: BrandsPageProps) => {
  const [appState] = useAppState()

  const [brandsList, setBrandsList] = useState({
    brandsListData: [],
    isBrandsListHere: false,
    isBrandsListLoading: true,
  })

  const [categorizedData, setCategorizedData] = useState([])

  const [featured, setFeatured] = useState({})
  const [userId, setUserId] = useState(null)
  const classes = styles()

  const [selectedFilter, setSelectedFilter] = useState(
    appState.settings.brandsView?.value || 1
  )
  const [selectedCategoryFilter, changeCategorySelectedFilter] = useState(1)
  const [searchText, setSearchText] = useState('')
  const [selectedGroup, setSelectedGroup] = useState([])
  const [selectedKeys, setSelectedKeys] = useState([])
  const filterTypes = {
    alphabet: 1,
    cashbackAsc: 2,
    cashbackDesc: 3,
  }
  const [filterResultLength, setResultLength] = useState(null)
  const [isAddExtensionPopUpOpen, setIsAddExtensionPopUpOpen] = useState(false)

  const [noCashbackExists, setNoCashbackExists] = useState(false)
  const [showScrollBtn, setShowScrollBtn] = useState(false)

  const changeSelectedFilter = filter => {
    setSelectedFilter(filter)
    handleSortChange(filter)
  }

  const onSearchTextChanged = debounce((text: string) => {
    setSearchText(text)
  }, 300)

  const filterByCategory = (array: any) => {
    const filterArray = (filterType: string) =>
      array.filter((ele: any) => {
        if (ele.category && ele.category.length) {
          return ele.category.includes(filterType)
        }
      })

    if (selectedCategoryFilter == BRANDS_CATEGORIES.all.value) {
      return array
    } else if (selectedCategoryFilter == BRANDS_CATEGORIES.fashion.value) {
      return filterArray(BRANDS_CATEGORIES.fashion.label)
    } else if (selectedCategoryFilter == BRANDS_CATEGORIES.beauty.value) {
      return filterArray(BRANDS_CATEGORIES.beauty.label)
    } else if (selectedCategoryFilter == BRANDS_CATEGORIES.home.value) {
      return filterArray(BRANDS_CATEGORIES.home.label)
    } else if (selectedCategoryFilter == BRANDS_CATEGORIES.lifestyle.value) {
      return filterArray(BRANDS_CATEGORIES.lifestyle.label)
    } else if (selectedCategoryFilter == BRANDS_CATEGORIES.travel.value) {
      return filterArray(BRANDS_CATEGORIES.travel.label)
    } else if (selectedCategoryFilter == BRANDS_CATEGORIES.fitness.value) {
      return filterArray(BRANDS_CATEGORIES.fitness.label)
    } else if (selectedCategoryFilter == BRANDS_CATEGORIES.tech.value) {
      return filterArray(BRANDS_CATEGORIES.tech.label)
    } else if (selectedCategoryFilter == BRANDS_CATEGORIES.pets.value) {
      return filterArray(BRANDS_CATEGORIES.pets.label)
    } else if (selectedCategoryFilter == BRANDS_CATEGORIES.kids.value) {
      return filterArray(BRANDS_CATEGORIES.kids.label)
    } else if (
      selectedCategoryFilter == BRANDS_CATEGORIES['health & wellness'].value
    ) {
      return filterArray(BRANDS_CATEGORIES['health & wellness'].label)
    } else if (
      selectedCategoryFilter == BRANDS_CATEGORIES['eco-friendly'].value
    ) {
      return filterArray(BRANDS_CATEGORIES['eco-friendly'].label)
    }
  }

  const handleSortChange = filter => {
    if (
      categorizedData &&
      categorizedData.length > 0 &&
      brandsList.brandsListData &&
      brandsList.brandsListData.length > 0 &&
      filter !== selectedFilter
    ) {
      if (filter == filterTypes.alphabet) {
        const AlphabetGroupedList = mapWordsToChars(categorizedData)
        const charsArray = keys(AlphabetGroupedList).sort()
        setSelectedKeys(charsArray)
        setSelectedGroup(AlphabetGroupedList)
      } else if (filter == filterTypes.cashbackAsc) {
        const rangeGroupedList = mapAmountToRanges(
          sort(
            sortAscByAmount,
            sort(
              (a, b) => a.brandName.localeCompare(b.brandName),
              categorizedData
            )
          )
        )
        const rangesKeys = keys(rangeGroupedList)
        setSelectedKeys(rangesKeys)
        setSelectedGroup(rangeGroupedList)
      } else if (filter == filterTypes.cashbackDesc) {
        const descRangeGroupedList = mapAmountToRanges(
          sort(
            sortDescByAmount,
            sort(
              (a, b) => a.brandName.localeCompare(b.brandName),
              categorizedData
            )
          )
        )
        const rangesKeys = keys(descRangeGroupedList)
        setSelectedKeys(rangesKeys)
        setSelectedGroup(descRangeGroupedList)
      }
    }
  }

  useEffect(() => {
    if (brandsList.brandsListData && brandsList.brandsListData.length > 0) {
      let filteredBySearch
      if (searchText) {
        filteredBySearch = brandsList.brandsListData.filter((obj: any) =>
          obj.brandName
            .toLowerCase()
            .trim()
            .includes(searchText.toLowerCase().trim())
        )
      } else {
        filteredBySearch = brandsList.brandsListData
      }
      const result = filterByCategory(filteredBySearch)
      setCategorizedData(result)
      let AlphabetGroupedList: any = []
      let rangeGroupedList: any = []
      let descRangeGroupedList: any = []
      let charsArray: any = []
      let rangesKeys: any = []

      if (result && result.length > 0) {
        // Regroup brands after getting search results
        setResultLength(result.length)
        AlphabetGroupedList = mapWordsToChars(result)
        rangeGroupedList = mapAmountToRanges(sort(sortAscByAmount, result))
        descRangeGroupedList = mapAmountToRanges(sort(sortDescByAmount, result))
        charsArray = keys(AlphabetGroupedList)
        rangesKeys = keys(rangeGroupedList)
        charsArray.sort()
      } else {
        setResultLength(0)
      }

      if (selectedFilter == filterTypes.alphabet) {
        setSelectedKeys(charsArray)
        setSelectedGroup(AlphabetGroupedList)
      } else if (selectedFilter == filterTypes.cashbackAsc) {
        setSelectedKeys(rangesKeys)
        setSelectedGroup(rangeGroupedList)
      } else if (selectedFilter == filterTypes.cashbackDesc) {
        setSelectedKeys(rangesKeys)
        setSelectedGroup(descRangeGroupedList)
      }
    } else {
      return
    }
  }, [selectedCategoryFilter, searchText, brandsList.isBrandsListHere])

  useEffect(() => {
    Promise.all([
      axios.get(`${baseURL}${ENDPOINTS.brandsList}?website=1`),
      axios.get(`${baseURL}${ENDPOINTS.featuredBrands}`),
    ])
      .then(([brandsResponse, featuredResponse]) => {
        const { data } = brandsResponse.data
        setBrandsList({
          brandsListData: data,
          isBrandsListHere: true,
          isBrandsListLoading: false,
        })
        setFeatured(
          featuredResponse.data.data.brands
            .filter(b => b.product_count > 0)
            .reduce((acc, v) => {
              acc[v.brand_id] = v.brand_name
              return acc
            }, {})
        )
        return Auth.currentUserInfo()
      })
      .then(currentUserInfo => {
        if (currentUserInfo)
          setUserId(currentUserInfo.attributes['custom:user_id'])
      })
      .catch(error => {
        setBrandsList({
          brandsListData: [],
          isBrandsListHere: false,
          isBrandsListLoading: false,
        })
        return error
      })

    let pageIndex = Math.floor(Math.random() * 4)
    if (
      !window.localStorage.getItem('extensionDownloaded') &&
      pageIndex === 0
    ) {
      setIsAddExtensionPopUpOpen(true)
    }
    setItemToLocalStorage('enterPage', 'true')
  }, [])

  useEffect(() => {
    const getUserEarnings = async () => {
      if (!appState.userId) return
      await axios
        .get(ENDPOINTS.user.replace(':id', appState.userId))
        .then(result => {
          if (result?.data?.data?.financialData.pending === 0) {
            setNoCashbackExists(true)
          }
          return result
        })
    }
    getUserEarnings().catch(() => setNoCashbackExists(true))
  }, [appState.userId])

  useEffect(() => {
    window.onscroll = () => {
      const minScroll = 150

      if (
        document.body.scrollTop >= minScroll ||
        document.documentElement.scrollTop >= minScroll
      ) {
        setShowScrollBtn(true)
      } else {
        setShowScrollBtn(false)
      }
    }
    return () => {
      window.onscroll = null
    }
  }, [])

  const closeAddExtensionPopUp = () => {
    setIsAddExtensionPopUpOpen(false)
  }

  const scrollUp = () => {
    setNoCashbackExists(!noCashbackExists)
    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  const trackClickStream = (event: string, params: Record<string, unknown>) => {
    tracker.trackCs(event, {
      section: 'brands-page',
      ...params,
    })
  }

  return (
    <Layout>
      <SEO
        title={t('brands.metaTitle')}
        description={t('company.metaDescription')}
      />
      <BrandsHeader
        alphabetList={selectedKeys}
        selectedFilter={selectedFilter}
        onSearchTextChanged={onSearchTextChanged}
      />

      <CHRContentContainer>
        <div className={classes.sortContainer}>
          {/* <CHRBrandsCount count={filterResultLength && filterResultLength} /> */}
          <div></div>
          <div className={classes.controls}>
            <BrandsCategorySort
              onFilterChange={changeCategorySelectedFilter}
              selectedFilter={selectedCategoryFilter}
            />
            <CHRBrandsSort
              onFilterChange={changeSelectedFilter}
              selectedFilter={selectedFilter}
            />
          </div>
        </div>
      </CHRContentContainer>
      <CHRContentContainer>
        {brandsList.isBrandsListLoading && <CHRSpinner />}
        {!brandsList.isBrandsListLoading && brandsList.isBrandsListHere && (
          <>
            {brandsList.brandsListData.length > 0 ? (
              <div className={classes.sortContainer}>
                <CHRBrandsGroupList
                  groupedList={selectedGroup}
                  keysList={selectedKeys}
                  selectedFilter={selectedFilter}
                  userId={userId}
                  featuredBrands={featured}
                />
              </div>
            ) : (
              <div className={classes.brandsListError}>
                <Typography variant="h1" component="h6">
                  {t('messages.nothingHere')}
                </Typography>
              </div>
            )}
            <p className={classes.disclosure}>
              This page contains affiliate links which means Chirpyest may make
              commission from sales generated from the links.
            </p>
          </>
        )}
        {!brandsList.isBrandsListLoading && !brandsList.isBrandsListHere && (
          <div className={classes.brandsListError}>
            <Typography variant="h1" component="h6">
              {t('messages.somethingWentWrong')}
            </Typography>
          </div>
        )}
      </CHRContentContainer>
      <AddExtensionPopup
        open={isAddExtensionPopUpOpen}
        handleClose={closeAddExtensionPopUp}
      />
      <button
        onClick={scrollUp}
        className={`${classes.scrollButton} ${
          showScrollBtn ? '' : classes.hidden
        }`}
      >
        <ArrowUpwardIcon fontSize="large" />
      </button>
    </Layout>
  )
}

export default withTranslation()(BrandsPage)
